// alltop.js

import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import AllTopContent from 'components/AllTopContent'

function AllTopPage({ location }) {
	// Page-Title Override
	// const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	const pageTitle = "'AllTop' Aggregator Feed"

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<AllTopContent />
		</Layout>
	)
}

AllTopPage.propTypes = {
	location: PropTypes.object,
}

export default AllTopPage
